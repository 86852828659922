import React, { lazy, Suspense, useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Loader from "./Components/Common/Loader";
import { GetAppSettingData } from "./service/homepage";
import { useDispatch, useSelector } from "react-redux";
import { appSettingDataSelector } from "./redux/appSetting";
import DynamicPage from "./Components/Common/DynamicPage";
import { getDataFromAppSetting } from "./service/helper";
const HomePage = lazy(() => import("./Components/Screen/Home/index"));
const AboutPage = lazy(() => import("./Components/Screen/AboutUs/index"));
const BlogPage = lazy(() => import("./Components/Screen/Blog/index"));
const ContactPage = lazy(() => import("./Components/Screen/Contact/index"));

const AppRoutes = () => {
  const [currentPage, setCurrentPage] = useState("");
  const location = useLocation();
  const [commonData, setCommonData] = useState();

  useEffect(() => {
    if (location?.pathname.toString().includes("service")) {
      setCurrentPage("/services");
    } else {
      setCurrentPage(location.pathname);
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [location]);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetAppSettingData());
  }, [location]);
  const { loading, appSettingData } = useSelector(appSettingDataSelector);
  useEffect(() => {
    if (appSettingData?.items?.length > 0) {
      const newVal = getDataFromAppSetting(
        appSettingData?.items,
        "key_name",
        "allservice"
      );
      setCommonData(newVal);
    }
  }, [appSettingData]);
  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route
          path="/"
          element={
            <HomePage
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          }
        />
        <Route
          path="/aboutus"
          element={
            <AboutPage
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          }
        />
        <Route
          path="/services"
          element={
            <BlogPage
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          }
        />
        {commonData &&
          commonData?.length > 0 &&
          commonData?.map((page) => (
            <Route
              key={`/services/${page.slug}`}
              path={`/services/${page.slug}`}
              element={
                <DynamicPage
                  path={`/services/${page.slug}`}
                  pageSlug={page.slug}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              }
            />
          ))}
        <Route
          path="/contact"
          element={
            <ContactPage
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          }
        />
        <Route
          path="*"
          exact={true}
          component={
            <HomePage
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          }
        />
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
