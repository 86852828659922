import React, { useEffect, useState } from "react";
import PageComponent from "./PageComponent";

const DynamicPage = ({
  path,
  component,
  pageSlug,
  currentPage,
  setCurrentPage,
}) => {
  return (
    <PageComponent
      path={path}
      pageSlug={pageSlug}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
    />
  );
};

export default DynamicPage;
