export const getDataFromAppSetting = (arr, key, valueKey) => {
  let mainObj = {};
  if (arr?.length > 0) {
    arr.filter((data) => {
      if (data[key] === valueKey) {
        mainObj = {
          key_name: data?.key_name,
          key_value: data?.key_value,
          parent_key: data?.parent_key,
        };
      }
    });
    let mainObject = JSON.parse(mainObj?.key_value || "{}");
    return mainObject;
  } else {
    return "N/A";
  }
};

export const getSpecificValueInService = (arr, key, valueKey) => {
  let mainObj = "";
  if (arr?.length > 0) {
    arr.filter((data) => {
      if (data[key] === valueKey) {
        mainObj = data?.values;
      }
    });
    return mainObj;
  } else {
    return "N/A";
  }
};

export const getDataFromContentFul = (arr) => {
  let mainObj = {};
  if (arr?.items?.length > 0) {
    arr?.items?.map((data) => {
      mainObj[data?.fields?.description] = `https:${data?.fields?.file?.url}`;
    });
    return mainObj;
  } else {
    return "N/A";
  }
};
