import axios from "axios";
import ApiConfig from "./ApiConfig";
import { setLoading, setAppSettingData } from "../redux/appSetting";

export const GetAppSettingData = () => {
  return async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const resp = await axios.get(`${ApiConfig.getCmsData}`);
      if (resp?.status >= 200 && resp?.status < 300) {
        dispatch(setAppSettingData(resp?.data));
        dispatch(setLoading(false));
      } else {
        throw resp;
      }
    } catch (e) {
      dispatch();
      // setAppSettingDataError({
      //   isError: true,
      //   message: errorMessage(e),
      // })
      dispatch(setLoading(false));
    }
  };
};
