import React from "react";
import Footer from "./Footer";
import Header from "./Header";

const Layout = ({
  children,
  currentPage,
  setCurrentPage,
  contentData,
  imageData,
}) => {
  return (
    <>
      <Header
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        imageData={imageData}
        contentData={contentData}
      />
      {children}
      <Footer imageData={imageData} contentData={contentData} />
    </>
  );
};

export default Layout;
