import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { appSettingDataSelector } from "../../redux/appSetting";
import {
  getDataFromAppSetting,
  getSpecificValueInService,
} from "../../service/helper";
import Layout from "./Layout";
import CommonTitle from "./CommonTitle";
import Loader from "./Loader";

const PageComponent = ({ path, pageSlug, currentPage, setCurrentPage }) => {
  const [commonData, setCommonData] = useState();
  const { loading, appSettingData } = useSelector(appSettingDataSelector);
  useEffect(() => {
    if (appSettingData?.items?.length > 0) {
      const newVal = getDataFromAppSetting(
        appSettingData?.items,
        "key_name",
        pageSlug
      );
      setCommonData(newVal);
    }
  }, [appSettingData]);
  return (
    <Layout currentPage={currentPage} setCurrentPage={setCurrentPage}>
      {loading && <Loader />}
      <CommonTitle
        titleName={getSpecificValueInService(commonData, "type", "main-title")}
      />
      <div className="main-section-container">
        <div className="container-box">
          <div className="aboutText price-list">
            {commonData &&
              commonData?.length > 0 &&
              commonData?.map((subData, index) => {
                if (subData?.type === "main-title") {
                  return (
                    <div className="d-flex align-items-center" key={index}>
                      <h5 style={{ marginBottom: "0" }}>
                        {subData?.values || "LASHES & BROWS"}
                      </h5>
                      <span className="headTag">Sercives</span>
                    </div>
                  );
                }
                if (subData?.type === "paragraph") {
                  return (
                    <p key={index}>{subData?.values || "LASHES & BROWS"}</p>
                  );
                }
                if (subData?.type === "sub-title") {
                  return <h5 key={index}>{subData?.values || "LASHES"}</h5>;
                }
                if (subData?.type === "pricing-table") {
                  return (
                    <div className="mb-5" key={index}>
                      {subData?.values?.map((tableData, subIndexes) => {
                        return (
                          <div
                            key={subIndexes}
                            className="d-flex justify-content-between time-table"
                          >
                            <div>
                              <p>
                                {tableData?.title || "Russian Lashes Full Set"}
                              </p>
                              {tableData?.subData?.length > 0 && (
                                <span>{tableData?.subData}</span>
                              )}
                            </div>
                            <p className="time-span">
                              £{tableData?.price || "65"}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  );
                }
              })}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PageComponent;
