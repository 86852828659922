import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import "./assets/css/common.css";
import "./index.css";
import { Provider } from "react-redux";
import Route from "./route";
import "bootstrap/dist/css/bootstrap.min.css";
import "./comman/index.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { store } from "./redux/store";
function App() {
  return (
    <Provider store={store}>
      <Router>
        <Route />
      </Router>
    </Provider>
  );
}

export default App;
