import React, { useEffect, useState } from "react";
import logo from "../../assets/svg/logo.webp";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { appSettingDataSelector } from "../../redux/appSetting";
import { getDataFromAppSetting } from "../../service/helper";
const Footer = ({ contentData, imageData }) => {
  const [commonData, setCommonData] = useState();
  const { loading, appSettingData } = useSelector(appSettingDataSelector);
  useEffect(() => {
    if (appSettingData?.items?.length > 0) {
      const newVal = getDataFromAppSetting(
        appSettingData?.items,
        "key_name",
        "common"
      );
      setCommonData(newVal);
    }
  }, [appSettingData]);
  return (
    <section>
      <div className="footer">
        <div className="footer-layer"></div>
        <div className="container-box text-center">
          <img
            src={commonData?.mainLogoUrl || logo}
            alt="Main Logo"
            className="techsible-header-logo"
          />
          <h5>{commonData?.footerContent}</h5>
          <div className="mx-auto footer-news-latter">
            {/* <Link to="/aboutus" className="Button">
              Book Now
            </Link> */}
            <a
              href="https://widget.treatwell.co.uk/place/vama-brows-beauty/"
              target="blank"
            >
              <button
                className="book-now-button"
                style={{ fontSize: "medium" }}
              >
                Book Now
              </button>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
