import React, { useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { ReactComponent as Hamburger } from "../../assets/svg/hamburger.svg";
import { ReactComponent as CloseIcon } from "../../assets/svg/close-icon.svg";
import logo from "../../assets/svg/logo.webp";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { appSettingDataSelector } from "../../redux/appSetting";
import { getDataFromAppSetting } from "../../service/helper";
import Loader from "./Loader";

const Header = ({ currentPage }) => {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [commonData, setCommonData] = useState();
  const { loading, appSettingData } = useSelector(appSettingDataSelector);
  useEffect(() => {
    if (appSettingData?.items?.length > 0) {
      const newVal = getDataFromAppSetting(
        appSettingData?.items,
        "key_name",
        "common"
      );
      setCommonData(newVal);
    }
  }, [appSettingData]);
  return (
    <>
      {loading && <Loader />}
      <Offcanvas
        show={show}
        onHide={handleClose}
        className="humMenu"
        placement="end"
      >
        <div className="main-drawer">
          <div className="main-drawer-header">
            <img
              src={commonData?.mainLogoUrl || logo}
              alt="Main Logo"
              className="techsible-header-logo"
              onClick={() => navigate("/")}
            />
            <CloseIcon onClick={handleClose} className="mobile-hamburger" />
          </div>
          <div className="main-drawer-menus">
            <ul className="snip1226 drawer-meuns-ul">
              <li className={`${currentPage == "/" ? "current" : ""}`}>
                <Link to="/" data-hover="Home">
                  Home
                </Link>
              </li>
              <li className={`${currentPage == "/aboutus" ? "current" : ""}`}>
                <Link to="/aboutus" data-hover="About Us">
                  About Us
                </Link>
              </li>
              <li className={`${currentPage == "/services" ? "current" : ""}`}>
                <Link to="/services" data-hover="Services">
                  Services
                </Link>
              </li>
              <li className={`${currentPage == "/contact" ? "current" : ""}`}>
                <Link to="/contact" data-hover="Contact Us">
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </Offcanvas>
      <div className="header-container">
        <img
          src={commonData?.mainLogoUrl || logo}
          alt="Main Logo"
          className="techsible-header-logo"
          onClick={() => navigate("/")}
        />
        <div className="header-desktop-menu">
          <ul className="snip1226">
            <li className={`${currentPage == "/" ? "current" : ""}`}>
              <Link to="/" data-hover="Home">
                Home
              </Link>
            </li>
            <li className={`${currentPage == "/aboutus" ? "current" : ""}`}>
              <Link to="/aboutus" data-hover="About Us">
                About Us
              </Link>
            </li>
            <li className={`${currentPage == "/services" ? "current" : ""}`}>
              <Link to="/services" data-hover="services">
                services
              </Link>
            </li>
            <li className={`${currentPage == "/contact" ? "current" : ""}`}>
              <Link to="/contact" data-hover="Contact us">
                Contact us
              </Link>
            </li>
          </ul>
        </div>
        <div className="mobile-support-container">
          <Hamburger onClick={handleShow} className="mobile-hamburger" />
        </div>
      </div>
    </>
  );
};
export default Header;
