import React, { useEffect, useState } from "react";
import { appSettingDataSelector } from "../../redux/appSetting";
import { useSelector } from "react-redux";
import { getDataFromAppSetting } from "../../service/helper";
import TitleBgImage from "../../assets/images/header-bg.jpg";
import Loader from "./Loader";

const CommonTitle = ({ titleName }) => {
  const [commonData, setCommonData] = useState();
  const { loading, appSettingData } = useSelector(appSettingDataSelector);
  useEffect(() => {
    if (appSettingData?.items?.length > 0) {
      const newVal = getDataFromAppSetting(
        appSettingData?.items,
        "key_name",
        "common"
      );
      setCommonData(newVal);
    }
  }, [appSettingData]);
  return (
    <>
      {loading && <Loader />}
      <div
        className="main-section-container third-main-section main-herder-section"
        style={{
          backgroundImage: `url(${
            commonData?.commonTitleBgImage || TitleBgImage
          })`,
        }}
      >
        <div className="container-box">
          <div className="third-section-container">
            <div className="text-center mb-3">
              <h4 className="section-main-title main-herder-text">
                {titleName || "ABOUT US"}
              </h4>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CommonTitle;
